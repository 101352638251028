import React from 'react'
import { graphql } from 'gatsby'
import bg from '../../static/images/bg_4.jpg';
import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'


// Export Template for use in CMS preview
export const CompPageTemplate = ({
                                         title,
                                         subtitle,
                                         featuredImage,
                                         section1,
                                         section2,
                                         section3,
                                         section4,
                                         section5,
                                         section6,
                                         section7,
                                         section8,
                                         body,
                                       }) => (
  <main>
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={bg}
    />
    <section className="section">
      <div className="container">
        <Content source={section1} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section2} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section3} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section4} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section5} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section6} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section7} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={section8} />
      </div>
    </section>

  </main>
)

const CompPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <CompPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default CompPage

export const pageQuery = graphql`
  query CompPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
        section3
        section4
        section5
        section6
        section7
        section8
      }
    }
  }
`
